import React from 'react';
import { db } from './firebase.js';
import moment from 'moment';

export default class Test extends React.Component {
  constructor() {
    super();
    this.state = { };
  }

  async callfns2() {
    const url = "https://us-central1-firepomo.cloudfunctions.net/fns/bob"
    const token = await this.props.user.getIdToken()
    let headers = {'Authorization': `Bearer ${token}`};
    try {
      let res = await fetch(url, {headers,})
      let text = await res.text()
      this.setState({answer:text})
      console.log(text);
    } catch(err) {
      console.error(err)
    }
  }

  async componentDidMount() {
    //this.callfns2();

    let days_cref = db.collection("users").doc(this.props.user.uid).collection("days")
    let days_qs = await days_cref.get()
    let days = {}

    for (let i in days_qs.docs) {
      let day_qds = days_qs.docs[i]
      let tasks_qs = await days_cref.doc(day_qds.id).collection("tasks").get()
      this.setState({"days_loaded": i})
      let day = day_qds.data()
      day.tasks = []
      for( let j in tasks_qs.docs) {
        let task_qds = tasks_qs.docs[j]
        let task = task_qds.data()
        day.tasks[task_qds.id] = task
      }
      days[day_qds.id]=day
    }

    let logs = []
    let logs_cref = db.collection("users").doc(this.props.user.uid).collection("log")
    let logs_qs = await logs_cref.get()
    for (let i in logs_qs.docs) {
      let log_qds = logs_qs.docs[i]
      let log = log_qds.data()
      logs.push(log)
    }
    logs.sort((a,b)=>(
      (a['timestamp'].toDate() < b['timestamp'].toDate()) ? -1: 1 
    ))
    
    let weeks = {}
    let weeks_cref = db.collection("users").doc(this.props.user.uid).collection("weeks")
    let weeks_qs = await weeks_cref.get()
    for (let i in weeks_qs.docs) {
      let week_qds = weeks_qs.docs[i]
      let week = week_qds.data()
      weeks[week_qds.id] = week;
    }
    
    let quarters = {}
    let quarters_cref = db.collection("users").doc(this.props.user.uid).collection("quarters")
    let quarters_qs = await quarters_cref.get()
    for (let i in quarters_qs.docs) {
      let quarter_qds = quarters_qs.docs[i]
      let quarter = quarter_qds.data()
      quarters[quarter_qds.id] = quarter;
    }

    this.setState({days, logs, weeks, quarters})
    
  }

  render() {
    const showpre= (name) => (
        this.state[name]
        ? <pre style={{color: "white"}}> {JSON.stringify(this.state[name], null, 2)} </pre>
        : <div>{name} missing</div>
    );

    const logs2csv = (logs) => (
      "date,time,project,task,estimate,current,finished\n"+
      logs.map((o)=>
        ( [ o["timestamp"].toDate().toLocaleString(), 
          JSON.stringify(o["project"] || ""),
          JSON.stringify(o["task"] || ""),
          JSON.stringify(o["estimate"] || 0),
          JSON.stringify(o["current"] || 0),
          JSON.stringify(o["finished"] || false)
        ].join(",") )
      ).join("\n")
    );

    const show = (name) => {
      if (!this.state[name]) {
        return (<div>loading {name}</div>)
      }

      if (name == "logs" && this.state[name]) {
        return (<div><a href={`data:text/plain;charset=utf-8,${encodeURIComponent(logs2csv(this.state[name]))}`} download={name+".csv"}>Download {name}.csv</a></div>)
      }
      const res = JSON.stringify(this.state[name], null, 2)
      return <div><a href={`data:text/plain;charset=utf-8,${encodeURIComponent(res)}`} download={name+".json"}>Download {name}.json</a></div>
    };
    
    return (<div>
      Days Loaded: { this.state["days_loaded"] }
      { show("logs") }
      { show("days") }
      { show("weeks") }
      { show("quarters") }
    </div>)
  }
}
