import firebase from 'firebase'
const config =   {
  apiKey: "AIzaSyAURAxH76d52HqfowiZ43G84Y_93FOUdCY",
  authDomain: "firepomo.firebaseapp.com",
  databaseURL: "https://firepomo.firebaseio.com",
  projectId: "firepomo",
  storageBucket: "firepomo.appspot.com",
  messagingSenderId: "268413114706"
};

firebase.initializeApp(config)
export default firebase;
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();

export let db = firebase.firestore();
db.settings({ cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED });

db.enablePersistence({synchronizeTabs:true}).catch(err => {
  if (err.code == 'failed-precondition') {
    console.log("Multiple tabs open, persistence can only be enabled in one tab at a a time.")
  } else if (err.code == 'unimplemented') {
    console.log("The current browser does not support all of the features required to enable persistence")
  }
});
