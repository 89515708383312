import React from 'react';
import { db } from './firebase.js';
import Task from './Task'
import moment from 'moment';
import FireForm from './FireForm'
import Logo from "./mini-tomato.svg"
import { NavLink} from 'react-router-dom'
const img_style = {width:"10px", height:"10px", "marginBottom": "3px"}

export default class Day extends FireForm {
  constructor(props) {
    super(props)
    this.state = { productivity_score: 0, loading:true  }
  }

  curMoment() {
    const {match} = this.props
    const {year, month, date} = match.params
    const datestr = `${year}/${month}/${date}`
    return moment(datestr, "YYYY/MM/DD")
  }

  curDate() {
    return this.curMoment().format("YYYY-MM-DD")
  }

  fbref() {
    return db.collection("users").doc(this.props.user.uid).collection("days").doc(this.curDate())
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value.replace("`t", moment().format("HH:mm:ss\t")) })
  }
  
  handleTask(id) {
    this.setState({curtask: id});
  }

  render() {
    if (this.state.loading) {
      return (<div>Loading</div>)
    }
    const m = this.curMoment()
    const date = this.curDate();

    document.title = `${m.format("MMM DD")}`

    const {TextArea, Select} = this
    const user = this.props.user
    const productivity_score = this.state.productivity_score

    const numcols = 5

    let task = (id) => { 
      return (
        <Task key={id} id={id} date={date} user={user} working={this.state.curtask === id} handleTask={(t)=>this.handleTask(t)} /> 
      ); 
    };
    task = task.bind(this);

    const Tasks = () => {
      if (this.state.curtask != null) {
        // handle focused task view
        return (
          <React.Fragment>
            <tr>
              <td colSpan={numcols}> <h2>Current Task</h2> </td>
            </tr>
            <Task key={this.state.curtask} id={this.state.curtask} date={date} user={user} working={true} handleTask={(t)=>this.handleTask(t)} /> 
          </React.Fragment>
        )
      } else {
        // handle all tasks view
        return (
          <React.Fragment>
            {task(0)}
            <tr>
              <td></td>
              <td className="taskcell"><small>Name</small></td>
              <td className="taskctrl"><small>Done</small></td>
              <td className="taskctrl"><small>Target</small></td>
              <td></td>
            </tr>
            {task(1)}
            {task(2)}
            {task(3)}
            {task(4)}
            {task(5)}
            {task(6)}
            {task(7)}
            {task(8)}
            {task(9)}
            {task(10)}
          </React.Fragment>
        )
      }
    }

    return (
      <div>
        <form className="dayform">
        <table>
        <tbody>
          { Tasks() }      
          <tr>
            <td colSpan={numcols}>
              <h2>Notes</h2>
              <div>
                <TextArea rows={2} name="notes" />
              </div>
            </td>
          </tr>
          { this.state.curtask == null &&
            <tr>
              <td colSpan={numcols}>
                <div className="productivity">
                  <h2>Productivity Score</h2>
                  <em>How productive were you? What happened?</em>
                  <div className="productivity-score">
                    {Array.from({length: 10}, (item, index) => 
                       <div key={"score_"+index} className="score-group">
                         <input type="radio" value={index+1} name="productivity_score" checked={productivity_score===(index+1).toString()} onChange={this.handleChangeAndSave} ></input>
                         <label htmlFor={index+1}>{index+1}</label>
                       </div>
                    )}
                  </div>
                </div>
                <div>
                  <TextArea rows={1} name="productivity_notes" />
                </div>
              </td>
            </tr>
          }
        </tbody>
        </table>
        </form>
      </div>
    )
  }
}
