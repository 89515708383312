import React from 'react';
import firebase from 'firebase'
import { db } from './firebase.js';
import moment from 'moment';
import { Route, Link, NavLink, Switch, Redirect, HashRouter, useRouteMatch, useParams } from 'react-router-dom'
import FireForm from './FireForm'
import { RoutedTabs, NavTab } from "react-router-tabs";
import TimeKeeper from 'react-timekeeper'


import "react-router-tabs/styles/react-router-tabs.css";

 
function Topic() {
  let { topicId } = useParams();
  return <h3>Requested topic ID: {topicId}</h3>;
}

class BottleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props.defaultValue, timestamp:new Date(), time: moment().format("HH:mm"), type:'bottle' }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {fbref, doneUrl, history} = this.props
    // set the ref to the state
    console.log(doneUrl)
    fbref.set(this.state).then(()=>{
      history.push(doneUrl)
    }).catch(()=>{
      alert("failed to save")
    })
  }
  
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
    console.log(e.target.name, e.target.value)
  }

  render() {
    const {liquid_type, ounces, timestamp, time, endtime } = this.state
    return (
      <form onSubmit={this.handleSubmit}>
        <label> Ounces: <input name="ounces" type="number" value={this.state.ounces} onChange={this.handleChange}/> </label> <br/>
        <label htmlFor="formula">Formula:</label> <input type="radio" name="liquid_type"  id="formula" value="formula" checked={liquid_type == "formula"} onChange={this.handleChange}></input>
        <label htmlFor="milk">Milk: </label> <input type="radio" name="liquid_type"  id="milk" value="milk" checked={liquid_type == "milk"} onChange={this.handleChange}></input><br/>
        <label> Start Time: <input type='time' name='time' value={time} onChange={this.handleChange}></input> </label><br/>
        <label> End Time: <input type='time' name='endtime' value={endtime} onChange={this.handleChange}></input> </label><br/>
        <input type="submit" value="Submit" />
        </form>
    )
  }
}


function ShowEntry(entry) {
  const duration = moment(entry.endtime, "HH:mm").diff(moment(entry.time, "HH:mm"), "minutes")
  if (entry.type == "bottle" ) {
    return (<td>Drank {entry.ounces} ounces of {entry.liquid_type} in {duration} minutes</td>)
  } else {
    return (<td>Eh?</td>)
  }
}

function LogEntryView(entry) {
  // decide based on the entry what to show
  // if bottle, show information about it
  return (<tr>
          <td style={{fontFamily: "consolas", fontSize: "12px"}}>{moment(entry.time, "HH:mm").format("h:mm:ss a")}</td>
          {ShowEntry(entry)}
        </tr>)
}

export default class LogForm extends FireForm {
  constructor() {
    super();
    this.state = { loaded: false, newEntry: {} };
  }
  
  curMoment() {
    const {match} = this.props
    const {year, month, date} = match.params
    const datestr = `${year}/${month}/${date}`
    return moment(datestr, "YYYY/MM/DD")
  }
  curDate() {
    return this.curMoment().format("YYYY-MM-DD")
  }

  isToday() {
    const midnight = moment().startOf('day')
    return this.curMoment().isSame(midnight);
  }
  
  componentDidMount() {
    // log is stored as one big collection by user
    this.unsubscribe = this.fbref()
      .orderBy('timestamp', 'desc')
      .onSnapshot(querySnapshot => {
        let log = []
        querySnapshot.forEach( doc => {
          console.log(doc.data());
          log.push(doc.data());
        })
        this.setState({log, loaded:true})
      }, (err) => {
        this.setState({"error": `${err}`})
      });
  }

  fbref() {
    return db.collection("users").doc(this.props.user.uid).collection("days").doc(this.curDate()).collection("baby")
  }
  
  handleAction = (e, name) => {
    e.preventDefault();
    this.fbref().add({
      timestamp: new Date(),
      action: name
    });
  }
  handleDelete = (e, entry) => {
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  getSum(liquid_type) {
    // sum it up
    let sum = 0
    const {log} = this.state
    for(var i=0; i<log.length; i++) {
      if (log[i].type == "bottle" && log[i].liquid_type == liquid_type) {
        sum += log[i].ounces
      }
    }
    return sum
  }

  render() {
    if (!this.state.loaded) {
      return (<div>Loading</div>)
    }
    const {match, history} = this.props
    const log = this.state.log
    const cells = log.map(
      (entry) => (
        <LogEntryView key={entry.timestamp} {...entry} />
        )
    );
    const m = this.curDate()

    return (
    <div>
      <NavTab to={`${match.url}/nurse`}>nurse</NavTab>
      <NavTab to={`${match.url}/bottle`}>bottle</NavTab>
      <NavTab to={`${match.url}/diaper`}>diaper</NavTab>
      <NavTab to={`${match.url}/tummy`}>tummy</NavTab>
      <NavTab to={`${match.url}/sleep`}>sleep</NavTab>
      <Switch>
        <Route path={`${match.path}/bottle`}>
          <BottleForm history={history} doneUrl={match.url} fbref={this.fbref().doc()} defaultValue={{ounces:2, liquid_type:"formula"}} />
        </Route>
        <Route path={`${match.path}/:topicId`}>
          <Topic />
        </Route>
        <Route path={match.path}>
        </Route>
      </Switch>

      <div>
        Total Bottle Formula Today: {this.getSum("formula")}<br/>
        Total Bottle Milk Today: {this.getSum("milk")} <br/>
      </div>


      <table id="tasklog" style={{width: "100%"}}>
        <thead>
          <tr>
            <th>Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        { cells }
        </tbody>
      </table>
    </div>
    );
  }

}
