import React, { Component } from 'react';
import './App.css';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Route, Link, NavLink, Switch, Redirect} from 'react-router-dom'
import Logo from "./mini-tomato.svg"
import Day from './Day'
import Week from './Week';
import Quarter from './Quarter';
import ExportData from "./ExportData";
import LogForm from "./LogForm";
import BabyLog from "./BabyLog";

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, routeProps, rest);
    }}/>
  );
}


const img_style = {width:"20px", height:"20px"}

class Header extends Component {
  curDate() {
    let {match} = this.props;
    const {year, month, date} = match.params
    const datestr = `${year}/${month}/${date}`
    return moment(datestr, "YYYY/MM/DD")
  }
  
  handleButton = (event) => {
    const t = event.target;
    let m = this.curDate();
    if (t.name === "nextday") {
      m = m.add(1, "days")
    } else {
      m = m.subtract(1, "days")
    }
    this.handleDateChange(m)
  }
  
  handleDateChange = (moment) => {
    const timeunit = this.props.match.params.timeunit
    this.props.history.push(`/${timeunit}/${moment.format("YYYY/MM/DD")}`)
  };

  render() {
    let {user, logout} = this.props;
    const m = this.curDate()

    return (
        <div className='header' style={{}}>
          <span><Link to="/"><img src={Logo} style={img_style} id="logo"/></Link></span>
          <NavLink to={"/quarter/"+m.format("YYYY/MM/DD")}>Quarter</NavLink>
          <NavLink to={"/week/"+m.format("YYYY/MM/DD")}>Week</NavLink>
          <NavLink to={"/day/"+m.format("YYYY/MM/DD")}>Day</NavLink>
          <NavLink to={"/log/"+m.format("YYYY/MM/DD")}>Log</NavLink>
          <span className="datepickerspan" >
            <input type="button" className="dayctl" name="prevday" value="<" onClick={this.handleButton} />
            <div style={{display: "inline-block"}}> 
              <DatePicker selected={m} onChange={this.handleDateChange} dateFormat="  ddd, MMM DD YYYY" /> 
            </div>
            <input type="button" className="dayctl daynext" name="nextday" value=">" onClick={this.handleButton} />
          </span>
          <a onClick={logout}>
            <img style={img_style} src={user.photoURL} />
          </a>
        </div>
    )
  }
}

function Cond(param, cases, props) {
  const Component = cases[param]
  return <Component {...props} />
}

function Title({title}) {
  document.title = title;
  return null
}

export default class App extends Component {
  handleKeyPress(event) {
    if (event.target.type !== 'textarea' && event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  render() {
    const props = this.props;
    const today =  moment().format("YYYY/MM/DD")
    
    return (
        <Switch>
          <PropsRoute exact path="/export" {...props} component={ExportData} />
          <PropsRoute path="/:timeunit(week|day|quarter|log|baby)/:year/:month/:date" {...props} component={
            (props)=>(
              <div key={props.match.url}>
                <Title title={props.match.params.timeunit.replace(/\w/, c => c.toUpperCase())} />
                <Header {...props} />
                <div className="contents">
                { Cond(props.match.params.timeunit, {"week": Week, "day":Day, "quarter":Quarter, "log":LogForm}, props) }
                </div>
              </div>
            )
          }/>
          <Redirect from="/week" to={"/week/"+today} />
          <Redirect from="/" to={"/day/"+today} />
        </Switch>
    );
  }
}
