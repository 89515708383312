import React, { Component } from 'react';
import { db } from './firebase.js';
import TomatoTimer from './TomatoTimer'
import FireForm from './FireForm'
import Clock from "./alarm-clock-svgrepo-com.svg"

import {LogRef} from "./LogForm"

function intToString(num) {
  return typeof(num) === "number" ? num.toString() :  ""
}

export default class Task extends FireForm {
  state = { task: "", estimate: "0", current: "0", finished: false };
  
  fbref() {
    // set up firebase callback, and if state is empty from firebase
    // set it to a default
    const date = this.props.date
    const taskid = intToString(this.props.id)
    //console.log("date", date, "task", taskid)
    //const ref = db.collection("users").doc(date).collection("tasks").doc(taskid)
    const ref = db.collection("users").doc(this.props.user.uid).collection("days").doc(date).collection("tasks").doc(taskid)
    return ref
  }
  
  logref() { return LogRef(this.props.user); }

  handleIncrement = () => {
    const newnum = parseInt(this.state.current || 0, 10) + 1
    const state = {current: newnum.toString()}
    this.logref().add({
      project: "pomo done", 
      ...this.state,
      current: state.current,
      timestamp: new Date()
    });
    this.fbref().set(state, {merge: true})
  }

  handleStop = () => {
    this.logref().add({
      project: "pomo cancel", 
      ...this.state,
      timestamp: new Date()
    });
    this.props.handleTask(null);
  }
  
  handleStart = () => {
    this.logref().add({
      project: "pomo begin", 
      ...this.state,
      timestamp: new Date()
    });
    this.props.handleTask(this.props.id);
  }

  handleCheckbox = (e) => {
    const t = e.target;
    const value = (t.type === 'checkbox')?t.checked:t.value;
    this.logref().add({
      project: "task " + (value?"checked":"unchecked"), 
      ...this.state,
      finished: value,
      timestamp: new Date()
    });
    this.handleChangeAndSave(e);
  }
  
  render() {
    const numcols = 5;
    if (!this.state) {
      return <tr><td colSpan={numcols}></td></tr>
    }
    const { TextInput, TextArea, Select, Checkbox } = this
    return (
      <React.Fragment>
        <tr className='taskitem'>
          <td className='taskdone'>
            <Checkbox name="finished" onChange={ this.handleCheckbox } />
          </td>
          <td className='taskcell'>
            <TextArea name="task" className={`task${this.state.finished?" taskchecked":""}`} /> 
          </td>
          <td className='taskctrl'>
            <TextInput name="current" size="2"  pattern="[0-9]*" />
          </td>
          <td className='taskctrl target'>
            <TextInput name="estimate" size="2"  pattern="[0-9]*" />
          </td>
          { this.props.working ?
            <td className='taskstop'>
            <button type="button" onClick={this.handleStop}>Stop</button>
            </td>
            :
            <td className='taskgo'>
            <button type="button" onClick={this.handleStart}><img src={Clock} id="Clock"/></button>
            </td>
          }
        </tr>
     
        {this.props.working && (
        <tr><td colSpan={numcols}>
          <TomatoTimer
            numCols={numcols} 
            pomoTime={25*60*1000}
            breakTime={5*60*1000}
            initiallyRunning={true} 
            onCancel={this.handleStop}
            onComplete={this.handleIncrement}
            onStart={this.handleStart}
          />
        </td></tr>
        )}
      </React.Fragment>
    )
  }
}
