import React from 'react';
import { render } from 'react-dom'
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import {auth, provider} from './firebase'

import { BrowserRouter as Router} from 'react-router-dom'
import marked from "marked";
import GoogleButton from 'react-google-button'



class AuthApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { user: auth.currentUser, authed: false};
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  login() {
    auth.signInWithRedirect(provider)
      .then((result) => {
        this.setState({user: result.user});
      })
  }
  logout() {
    auth.signOut().then(()=>{ this.setState({user: null}) });
  }
  componentDidMount() {
    const landingPath = require("./landing.md");
    fetch(landingPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          landingMd: marked(text)
        })
      })

    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({user});
      }
      this.setState({authed:true});
    });
  }
  render() {
    const props = {user: this.state.user, logout: this.logout}
    const { landingMd } = this.state;

    if (this.state.authed) {
      return (
        <div>
          {this.state.user ? 
            <Router>
              <App {...props}/>
            </Router>
            : (
              <div style={{padding:10}}>
                <GoogleButton style={{cursor: 'pointer', margin:2 }} onClick={this.login}/>
                <article dangerouslySetInnerHTML={{__html: landingMd }}></article>
              </div>
            )
          }
        </div>);
    } else {
      return (null);
    }
  }
}

render(
  <AuthApp/>,
  document.getElementById('root')
);
registerServiceWorker();
