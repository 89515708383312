import React from 'react';
import autosize from 'autosize';

// forms have inputs that update firebase
export default class FireForm extends React.Component {
  state =  { loading: true }
  myrefs = {}

  onSet = (e) => {
    this.fbref().set({_updated: new Date()}, {merge: true} )
  }

  handleChange = (e) => {
    this.setState({ [e.target.name] : e.target.value })
  }
  
  handleChangeAndSave = (e) => {
    const t = e.target;
    const value = (t.type === 'checkbox')?t.checked:t.value;
    this.fbref().set({
      [t.name]: value,
      _updated : new Date()
    }, {merge: true})
  }

  handleBlurAndSave = this.handleChangeAndSave

  TextInput = ({name, defaultValue, ...rest}) => {
    if (defaultValue === undefined) {
      defaultValue = ""
    }
    const handlers = { onChange: this.handleChange, onBlur: this.handleBlurAndSave }
    return (<input name={name} type="text" {...handlers} value={this.state[name]||defaultValue} {...rest} />)
  }
  
  TextArea = ({name, ...rest}) => {
    const handlers = { onChange: this.handleChange, onBlur: this.handleBlurAndSave }
    const myrefs = this.myrefs;
    return (<textarea name={name} {...handlers} value={this.state[name]||""} {...rest} ref={c => {myrefs[name]=c; /*autosize(c);*/ }}/>)
  }
  
  Select = ({name, children, ...rest}) => {
    const handlers = { onChange: this.handleChangeAndSave }
    return (<select name={name} {...handlers} value={this.state[name]||""} {...rest}>{children} </select>)
  }

  Checkbox = ({name, ...rest}) => {
    const handlers = { onChange: this.handleChangeAndSave }
    return (<input type="checkbox" name={name} {...handlers} checked={this.state[name]?true:false} {...rest} />)
  }

  // subscribes to firebase and sets state
  componentDidMount() {
    this.unsubscribe = this.fbref().onSnapshot((snapshot) => {
      let data = snapshot.data()
      if(data) {
        data.loading = false;
        console.log("data.loading", data.loading)
        this.setState(data)
      } else {
        this.setState({loading:false})
      }
    });
  }
  
  componentDidUpdate() {
    autosize(Object.values(this.myrefs))
    autosize.update(Object.values(this.myrefs))
  }

  // unsubscribes
  componentWillUnmount() {
    this.unsubscribe()
    autosize.destroy(Object.values(this.myrefs))
  }
}

function FormValues(target) {
  const data = new FormData(target);
  let o = {}
  for (var p of data) {
    o[p[0]]=p[1]
  }
  return o
}
