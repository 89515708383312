import React, { Component } from 'react';
import CountdownTimer from './CountdownTimer'

const STATE_POMODORO = 0,
      STATE_BREAK = 1,
      STATE_FORM_INTERRUPTED = 2,
      STATE_FORM_FOCUS_SURVEY = 3;

export default class TomatoTimer extends Component {
  constructor(props) {
    super(props);

    // props are:
    //   pomoTime
    //   breakTime
    //   onComplete
    //   onCancel

    // states are:
    //    Pomodoro / zeroTime
    //    Break / zeroTime
    //    InterruptForm
    //    FocusForm
    this.state = {
      view: STATE_POMODORO,
      zeroTime: Date.now() + this.props.pomoTime,
      pomoFinished: false
    };
    this.nextState = this.nextState.bind(this);
  }
  componentDidMount() {
  }
  componentWillUnmount() {
  }
  // at end of countdown, buzzer, and then complete the task
  countdownComplete = () => {
    window.desktopAlert();
    window.buzzer();
    this.setState({pomoFinished:true})
  }
  
  nextState(completed) {
    const startPomodoro = () => {
      console.log("starting a pomodoro");
      this.props.onStart();
      this.setState({view: STATE_POMODORO, zeroTime: Date.now() + this.props.pomoTime, pomoFinished: false});
    };
    const startBreak = () => {
        this.setState({view: STATE_BREAK, zeroTime: Date.now() + this.props.breakTime});
    };
    if (this.state.view === STATE_POMODORO ) {
      if (completed) {
        this.props.onComplete();
        //this.setState({view:STATE_FORM_FOCUS_SURVEY});
        startBreak();
      } else {
        this.setState({view:STATE_FORM_INTERRUPTED});
      }
    } else if (this.state.view === STATE_BREAK) {
      startPomodoro();
    } else if (this.state.view === STATE_FORM_INTERRUPTED) {
      startPomodoro();
    } else if (this.state.view === STATE_FORM_FOCUS_SURVEY) {
      startBreak();
    }
  }

  render() {
    const { onCancel } = this.props
    console.log("RENDER:" + this.state.view + "=>" + this.state.zeroTime);
    switch(this.state.view) {
      case STATE_POMODORO:
        return (
          <div className="timerbox">
          <h1>Focus</h1> <CountdownTimer key="countdowntimer" zeroTime={this.state.zeroTime} onCountdown={this.countdownComplete} isRunning={true} />
          <div className="taskbuttons">
          <button type="button" onClick={onCancel}>Give up</button>
          <button type="button" onClick={() => {this.nextState(true);}}>Done</button>
          </div>
          </div>
        );
      case STATE_FORM_FOCUS_SURVEY:
        return (
          <div className="timerbox">
          <h1>FOCUS SURVEY</h1>
          <button type="button" onClick={this.nextState}>Continue</button>
          </div>
        );
      case STATE_FORM_INTERRUPTED:
        return (
          <div className="timerbox">
          <h1>INTERRUPT SURVEY</h1>
          <button type="button" onClick={this.nextState}>Continue</button>
          </div>
        );
      case STATE_BREAK:
        return (
          <div className="timerbox">
          <h1> Break Time!! </h1>
          <div className="timer">
            <CountdownTimer key="breaktimer" zeroTime={this.state.zeroTime} onCountdown={this.countdownComplete} isRunning={true} />
          </div>
          <button type="button" onClick={()=>{this.nextState(true);}}>Continue</button>
          </div>
        );
    }
  }
}

TomatoTimer.defaultProps = {
  pomoTime: 25*60*1000,
  breakTime: 5*60*1000
};
