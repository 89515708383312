import React from 'react';
import { db } from './firebase.js';
import moment from 'moment';

import FireForm from './FireForm'

export default class Quarter extends FireForm {
  
  curDate() {
    let {match} = this.props;
    const {year, month, date} = match.params
    const datestr = `${year}/${month}/${date}`
    return moment(datestr, "YYYY/MM/DD")
  }
  
  quarter() {
    const m = this.curDate()
    return  (m.year() + " Q" + m.quarter())
  }

  fbref() {
    return db.collection("users").doc(this.props.user.uid).collection("quarters").doc(this.quarter())
  }

  render() {
    const {TextInput, TextArea, Select, Checkbox} = this
      
    const startQuarter = this.curDate().startOf("quarter")
    const endQuarter = this.curDate().endOf("quarter")

    document.title = `${this.quarter()}`
    return (
      <form>
        <h2> OKRs for: {this.quarter()}</h2>
        <em> {startQuarter.format("MMMM DD")}  -> {endQuarter.format("MMMM DD, YYYY")}</em>
        <h2>Objective</h2>
        <TextArea name="objective" rows={1}/>
        <h2>Key Result 1</h2>
        <TextArea name="kr1" rows={1}/>
        <h2>Key Result 2</h2>
        <TextArea name="kr2" rows={1}/>
        <h2>Key Result 3</h2>
        <TextArea name="kr3" rows={1}/>
        <h2>Key Result 4</h2>
        <TextArea name="kr4" rows={1}/>
        <h2>Key Result 5</h2>
        <TextArea name="kr5" rows={1}/>
        
        <h2>last updated</h2>
        {
          this.state["_updated"] && moment(this.state["_updated"].toDate()).fromNow()
        }
      </form>
    )
  }
}
