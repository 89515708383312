import React from 'react';
import { db } from './firebase.js';
import moment from 'moment';

import FireForm from './FireForm'

export default class Week extends FireForm {
  constructor(props) {
    super(props);
    this.state = {} 
  }
  
  curDate() {
    let {match} = this.props;
    const {year, month, date} = match.params
    const datestr = `${year}/${month}/${date}`
    return moment(datestr, "YYYY/MM/DD")
  }
  
  // returns the id for the week corresponding to today
  week() {
    const m = this.curDate()
    const week = m.isoWeekYear() + "-" + m.isoWeek()
    console.log('weekid:' + week)
    return week
  }

  fbref() {
    return db.collection("users").doc(this.props.user.uid).collection("weeks").doc(this.week())
  }
  
  render() {
    const {TextArea, Checkbox} = this

    const numcols = 2
    const nums = [0,1,2,3,4,5,6,7,8,9,10];
    const task = (b) => (
      <tr className="taskitem">
        <td className="taskdone"><Checkbox name={`t${b}.finished`} /></td>
        <td className="taskcell"><TextArea name={`t${b}`} className="task" size="" /></td>
      </tr>
    )
    const mon = this.curDate().isoWeekday(1)
    const monday = mon.format("ddd, MMM DD")
    const sunday = this.curDate().isoWeekday(7).format("ddd, MMM DD")

    document.title = `Week of ${mon.format("MMM DD")}`
    return (
      <form className="weektable">
      <table>
        <tbody>
          <tr>
            <td colSpan={numcols}>
              <h2>Most Important Tasks of the Week ({monday} -> {sunday})</h2>
              <em>If these were the only tasks you completed during the week you'd be satisfied</em>
            </td>
          </tr>
          
          {task(1)}
          {task(2)}
          {task(3)}
          {task(4)}
          {task(5)}

          <tr>
            <td colSpan={numcols}>
              <h2>Tasks of Secondary Importance</h2>
              <em>Do these only after you have completed the above tasks</em>
            </td>
          </tr>

          {task(6)}
          {task(7)}
          {task(8)}
          {task(9)}
          {task(10)}

          <tr>
            <td colSpan={numcols}>
              <h2>Additional Tasks</h2>
              <em>Do these only after you have completed the above tasks</em>
            </td>
          </tr>
          {task(11)}
          {task(12)}
          {task(13)}
          {task(14)}
          {task(15)}
          
          <tr>
            <td colSpan={numcols}>
            <div className="commitment">
              <h2>Commitment</h2>
              <em>What one action will you take to make this week more productive</em>
            </div>
            <div>
              <TextArea style={{width: "100%"}} name="committment" />
            </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        <div>
          <h2>Notes</h2>
          <TextArea rows={2} name="notes" />
        </div>
        <div>
          <h2>Weekly Wins</h2>
          <em> What's going well? Any wins (big or little) this week? </em>
          <TextArea rows={2} name="wins" />
        </div>
        <div>
          <h2>What have you learned this week?</h2>
          <TextArea rows={2} name="learning" />
        </div>
        <div>
          <h2>Next Week</h2>
          <em>What actions will you take to ensure next week is productive?</em>
          <TextArea rows={2} name="nextweek" />
        </div>
      </div>

      </form>
    );
  }
}

