import React, { Component } from 'react';
import PropTypes from 'prop-types';

/*
 * CountdownTimer
 * - calls function when it finishes
 * 
 * required props:
 *   zeroTime: (e.g., Date.now() + 5000)
 *   isRunning: true/false
 *
 * optional props:
 *   onCountdown: callback
 *   onClick: callback
 */
export default class CountdownTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeLeftMs: (this.props.zeroTime - Date.now())
    }
  }

  componentWillMount() {
    this.oldTitle = document.title;
  }

  componentWillUnmount() {
    console.log("ComponentWillUnmount");
    console.log(this.timeout);
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    document.title = this.oldTitle;
  }

  update = (start) => {
    const now = Date.now()
    const timeLeftMs = (this.props.zeroTime - now);
    const total_secs = Math.floor(timeLeftMs/1000)
    const repeatTimerSecs = this.props.repeatPeriod
    let lastCountDown = this.state.lastCountDown

    if (this.props.isRunning) {
      if (total_secs <= 0) {
        // call onCountdown if time since last onCountdown > X
        if (lastCountDown==null || (total_secs <= (lastCountDown - repeatTimerSecs)) ) {
          this.props.onCountdown && this.props.onCountdown();
          lastCountDown = total_secs;
        }
      }
    }
    this.setState({ timeLeftMs, lastCountDown })
  }

  render = () => {
    const { timeLeftMs } = this.state;
    let total_secs = Math.floor(timeLeftMs/1000)
    let sign = ""
    if (total_secs < 0 ) {
      sign = "-"
      total_secs = -total_secs;
    }
    const secs_left =  total_secs % 60;
    const mins_left =  Math.floor(total_secs / 60);
    this.timeout =  (this.props.isRunning ) ? setTimeout(this.update, 1000) : null;
    const time_string = `${sign}${mins_left.toString().padStart(2, '0')}:${secs_left.toString().padStart(2, '0')}`;
    if (this.oldTitle) { document.title = time_string; }
    return (
      <h2 className="timer" onClick={this.props.onClick}>{time_string} </h2>
    );
  }
}

CountdownTimer.propTypes = {
  zeroTime: PropTypes.number.isRequired,
  repeatPeriod: PropTypes.number.isRequired,
  isRunning: PropTypes.bool,
  onCountdown: PropTypes.func,
  onClick: PropTypes.func
}


CountdownTimer.defaultProps = {
  repeatPeriod: 30
}

