import React from 'react';
import firebase from 'firebase'
import { db } from './firebase.js';
import moment from 'moment';
import { NavLink} from 'react-router-dom'
    

export function LogRef(user) {
  return  db.collection("users").doc(user.uid).collection("log");
}

export default class BabyLog extends React.Component {
  constructor() {
    super();
    this.state = { loaded: false, newEntry: {} };
  }
  
  curDate() {
    let {match} = this.props;
    const {year, month, date} = match.params
    const datestr = `${year}/${month}/${date}`
    return moment(datestr, "YYYY/MM/DD").startOf('day');
  }

  isToday() {
    const midnight = moment().startOf('day')
    return this.curDate().isSame(midnight);
  }
  
  componentDidMount() {
    const today = this.curDate().toDate()
    const tomorrow = this.curDate().add(1, 'days').toDate()
    // log is stored as one big collection by user
    this.unsubscribe = this.fbref()
      .where('timestamp', '>', today)
      .where('timestamp', '<', tomorrow )
      .orderBy('timestamp', 'desc')
      .onSnapshot(querySnapshot => {
        let log = []
        querySnapshot.forEach( doc => {
          //console.log(doc.data());
          log.push(doc.data());
        })
        const last = log.length ? { project:log[0].project, task:""} : {}
        this.setState({log, loaded:true, newEntry: last})
      }, (err) => {
        this.setState({"error": `${err}`})
      });
  }

  fbref = () => (LogRef(this.props.user));
  
  handleChange = (e) => {
    let newEntry = this.state.newEntry 
    newEntry[e.target.name] = e.target.value
    this.setState({ newEntry })
  }
  
  handleSubmit = (e) => {
    const {newEntry} = this.state
    // clear entry state
    e.preventDefault();
    // add entry to fb
    this.fbref().add({
      ...newEntry,
      timestamp: new Date()
    });
    this.setState({newEntry: {project: newEntry.project, task:""}})
    this.task_input && this.task_input.focus();
  }
  
  TextInput = ({name, ...rest}) => {
    const handlers = { onChange: this.handleChange }
    return (<input name={name} type="text" ref={(input) => { this[name+"_input"] = input; }} {...handlers} value={this.state.newEntry[name]||""} {...rest} />)
  }


  componentWillUnmount() {
    this.unsubscribe();
  }


  render() {
    if (!this.state.loaded) {
      return (<div>Loading</div>)
    }
    const log = this.state.log
    const cells = log.map(
      (entry) => (
        <tr key={entry.timestamp}>
          <td style={{fontFamily: "consolas", fontSize: "12px"}}>{moment(entry.timestamp.toDate()).format("h:mm:ss a")}</td>
          <td>{entry.project}</td>
          <td>{entry.task}</td>
          <td>{entry.current}</td>
          <td>{entry.estimate}</td>
          <td>{ (entry.finished == true) ? "✔" : ""}</td>
        </tr>)
    );
    const TextInput = this.TextInput
    const m = this.curDate()
    return (
      <div>
        <div id="description">
          <h2><NavLink to={"/log/"+m.format("YYYY/MM/DD")}>Task Log</NavLink></h2>
          
          <em>Add an entry when you begin or continue a task</em>
        </div>
        <form style={{width:"100%"}} onSubmit={this.handleSubmit}> 
        <table id="tasklog" style={{width: "100%"}}>
          <thead>
            <tr>
              <th>Time</th>
              <th>Project</th>
              <th>Task</th>
              <th>Done</th>
              <th>Target</th>
              <th>✔</th>
            </tr>
          </thead>
          <tbody>
          { this.isToday() && 
            <tr>
              <td><input type="submit" value="Add" /></td>
              <td><TextInput name="project" style={{width:"100px"}} autoFocus placeholder="project"/></td>
              <td><TextInput name="task" style={{width:"100%"}} placeholder="task"/></td>
              <td/>
              <td/>
              <td/>
            </tr>
          }
          { cells }
          </tbody>
        </table>
        </form>
      </div>
    )
  }
}
